var render = function render(){
  var _vm$board, _vm$board2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 w-sm-120px",
    attrs: {
      "outlined": "",
      "color": "primary",
      "small": ""
    },
    on: {
      "click": _vm.like
    }
  }, [_c('i', {
    staticClass: "icon icon-recommend-primary mr-4"
  }), _c('span', [_vm._v("추천")])])], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "auto"
    }
  }, [_c('v-card', {
    staticClass: "h-100 min-w-sm-80px",
    attrs: {
      "outlined": "",
      "color": "grey-b3"
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 grey-ec d-flex align-center justify-center text-center"
  }, [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v(" " + _vm._s((((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.likeCount) - ((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : _vm$board2.dislikeCount)).format()) + " ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 w-sm-120px",
    attrs: {
      "outlined": "",
      "color": "grey-d",
      "small": ""
    },
    on: {
      "click": _vm.disLike
    }
  }, [_c('i', {
    staticClass: "icon icon-opposition mr-4"
  }), _c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("반대")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }