<template>
    <v-row class="row--x-small" justify="center">
        <v-col cols="4" sm="auto">
            <v-btn outlined color="primary" small class="w-100 w-sm-120px" @click="like">
                <i class="icon icon-recommend-primary mr-4"></i>
                <span>추천</span>
            </v-btn>
        </v-col>
        <v-col cols="4" sm="auto">
            <v-card outlined color="grey-b3" class="h-100 min-w-sm-80px">
                <div class="w-100 h-100 grey-ec d-flex align-center justify-center text-center">
                    <p class="page-text font-weight-medium">
                        {{ (board?.likeCount - board?.dislikeCount).format() }}
                    </p>
                </div>
            </v-card>
        </v-col>
        <v-col cols="4" sm="auto">
            <v-btn outlined color="grey-d" small class="w-100 w-sm-120px" @click="disLike">
                <i class="icon icon-opposition mr-4"></i>
                <span class="grey-6--text">반대</span>                        
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import api from '@/api';

export default {
    props: {
        board: { type: Object, default: null }
    },
    mounted() {
    },
    data() {
        return {
        };
    },
    methods: {
        async like() {
            let { board } = await api.v1.boards.like({ _id: this.board._id });
            this.$emit("init", board);
        },
        async disLike() {
            let { board } = await api.v1.boards.disLike({ _id: this.board._id });
            this.$emit("init", board);
        },
    }
};

</script>
